import { Component, OnInit, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { CarService } from "src/app/services/car.services";
import { OverlayService } from "src/app/services/overlay.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

const PILOT_BUSINESS_TYPES = {
  "plan-ovalo": 3,
  "0km": 1,
  usados: 2,
};

export function captchaValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value !== "success"
      ? { captcha: { value: control.value } }
      : null;
  };
}

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    reCaptchaLoad: () => void;
  }
}

export interface ReCaptchaConfig {
  theme?: "dark" | "light";
  type?: "audio" | "image";
  size?: "compact" | "normal";
  tabindex?: number;
}

const pilotFieldTranslations = {
  pilot_car_modelo: "Modelo de interés",
};

@Component({
  selector: "app-contacto",
  templateUrl: "./contacto.component.html",
  styleUrls: ["./contacto.component.scss"],
})
export class ContactoComponent implements OnInit {
  key = "6LdludkUAAAAABPPXBWUgPTVo-mIjqf2_pDGwlRx";
  config = {};
  widgetId: number;
  message = "";
  isError = false;
  contactForm: FormGroup = new FormGroup({});
  buyingTypeControl: FormControl = new FormControl("");
  vehicleModelControl: FormControl = new FormControl("", [Validators.required]);
  vehicleVersionControl: FormControl = new FormControl("");
  captchaValidated: FormControl = new FormControl("", [
    captchaValidator,
    Validators.required,
  ]);

  nameControl: FormControl = new FormControl(null, [Validators.required]);
  lastnameControl: FormControl = new FormControl(null, [Validators.required]);
  dniControl: FormControl = new FormControl(null, [
    Validators.pattern("[0-9]*"),
  ]);
  emailControl: FormControl = new FormControl(null, [
    Validators.email,
    Validators.required,
  ]);
  telControl: FormControl = new FormControl(null, [
    Validators.pattern("[0-9]*"),
    Validators.required,
  ]);

  dateControl: FormControl = new FormControl();
  campaniaControl: FormControl = new FormControl();
  platesControl: FormControl = new FormControl();
  vehicleYearControl: FormControl = new FormControl();
  commentsControl: FormControl = new FormControl();
  subjectControl: FormControl = new FormControl("", [Validators.required]);
  pilotBusinessType: FormControl = new FormControl();
  pilotAppKey: FormControl = new FormControl();
  pilotOrigin: FormControl = new FormControl();

  asuntos = [
    {
      value: "plan-ovalo",
      name: "Plan Ovalo",
      pilot_origin: "EBNLA0MBU5W4KEQYO",
      mailTo: "marketing@autosdelsurford.com",
    },
    {
      value: "0km",
      name: "0 Km",
      pilot_origin: "EBMH16K20OYVBCS2S",
      mailTo: "marketing@autosdelsurford.com",
    },
    {
      value: "posventa",
      name: "Posventa Sarandí",
      mailTo: "serviciossarandi@autosdelsurford.com",
    },
    {
      value: "posventa-quilmes",
      name: "Posventa Quilmes",
      mailTo: "serviciosquilmes@autosdelsurford.com",
    },
    {
      value: "posventa-varela",
      name: "Posventa Varela",
      mailTo: "ariastamara@autosdelsurford.com",
    },
    {
      value: "usados",
      name: "Usados",
      pilot_origin: "EBMH16K20OYVBCS2S",
      mailTo: "marketing@autosdelsurford.com",
    },
    {
      value: "accesorios",
      name: "accesorios",
      pilot_origin: "EBMH16K20OYVBCS2S",
      mailTo: "repuestossarandi@autosdelsurford.com",
    },
    {
      value: "seguros",
      name: "Beneficios Seguros",
      pilot_origin: "ETHEY3QCLV5W70G6C",
      mailTo: "serviciossarandi@autosdelsurford.com",
    },
    {
      value: "ford-pro",
      name: "Ford Pro",
      pilot_origin: "ETHF2E13HIMW604PC",
      mailTo: "esquivelgerardo@autosdelsurford.com",
    },
    {
      value: "trabaja-con-nosotros",
      name: "Trabajá con nosotros",
      mailTo: "personal@autosdelsurford.com",
    },
    // {
    //   value: "campania-plan-ovalo",
    //   name: "Trabajá con nosotros",
    //   pilot_origin: "EBNLA0MBU5W4KEQYO",
    // },
    // { value: "ford-protect", name: "Ford Protect" },
    // { value: "motorcraft", name: "motorcraft" },
    // { value: "quick-lane", name: "Quick Lane" },
    // { value: "otros", name: "Otros", pilot_origin: "EBNLBU5CHGYQCQ985" },
  ];
  carModels = [];
  campania = false;
  type;
  car;

  constructor(
    private activatedRoute: ActivatedRoute,
    private carService: CarService,
    private overlayService: OverlayService,
    private httpClient: HttpClient,
    private ngZone: NgZone
  ) {
    this.carModels = this.carService.getCars();
    this.vehicleModelControl.valueChanges.subscribe((newValue) => {
      this.car = this.carService
        .getCars()
        .find((model) => model.link == newValue);
      if (this.car && this.car.versiones && this.car.versiones.length > 0) {
        this.vehicleVersionControl.setValue(this.car.versiones[0]);
      } else {
        this.vehicleVersionControl.setValue("");
      }
    });

    this.buyingTypeControl.setValue("plan-ovalo");

    this.contactForm.addControl("name", this.nameControl);
    this.contactForm.addControl("pilot_lastname", this.lastnameControl);
    this.contactForm.addControl("dni", this.dniControl);
    this.contactForm.addControl("email", this.emailControl);
    this.contactForm.addControl("phone", this.telControl);
    this.contactForm.addControl("captchaValid", this.captchaValidated);
    this.contactForm.addControl(
      "pilot_business_type_id",
      this.pilotBusinessType
    );
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.type = params.tipo || "contacto";
      if (params.modelo) {
        this.car = this.carService
          .getCars()
          .find((car) => car.link === params.modelo);
        this.vehicleModelControl.setValue(params.modelo);
        this.vehicleModelControl.updateValueAndValidity();
      }
      params.modalidad && this.buyingTypeControl.setValue(params.modalidad);
      this.subject = params.subject || "posventa";
      params.campania &&
        this.campaniaControl.setValue(params.campania === "true");

      this.setFormConfig(params.tipo);
    });

    this.subjectControl.valueChanges.subscribe((newSubject) => {
      if (newSubject == "plan-ovalo" || newSubject == "0km") {
        this.contactForm.addControl(
          "pilot_car_modelo",
          this.vehicleModelControl
        );
      } else {
        this.contactForm.removeControl("pilot_car_modelo");
      }
    });

    window.reCaptchaLoad = () => {
      const config = {
        ...this.config,
        sitekey: this.key,
        callback: this.captchaSuccess.bind(this),
        "expired-callback": this.captchaExpire.bind(this),
      };
      const element = document.querySelector("#g-recaptcha");
      this.widgetId = this.render(element, config);
    };

    let script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  set subject(subject) {
    if (this.asuntos.find((asunto) => asunto.value === subject)) {
      this.subjectControl.setValue(subject);
    } else {
      this.subjectControl.setValue("otros");
    }
  }

  get subject() {
    return this.subjectControl.value;
  }

  private render(element, config): number {
    return grecaptcha.render(element, config);
  }

  setFormConfig(type) {
    switch (type) {
      case "cotizacion":
        this.contactForm.addControl(
          "pilot_car_modelo",
          this.vehicleModelControl
        );
        this.contactForm.addControl(
          "vehicle-version",
          this.vehicleVersionControl
        );

        this.pilotBusinessType.setValue(
          PILOT_BUSINESS_TYPES[this.buyingTypeControl.value]
        );

        this.buyingTypeControl.valueChanges.subscribe((newBuyingType) => {
          this.pilotBusinessType.setValue(PILOT_BUSINESS_TYPES[newBuyingType]);
        });
        break;
      case "campania-plan-ovalo":
        this.subject = "campania-plan-ovalo";
        this.contactForm.addControl(
          "pilot_car_modelo",
          this.vehicleModelControl
        );
        this.pilotBusinessType.setValue(PILOT_BUSINESS_TYPES["plan-ovalo"]);
        this.contactForm.removeControl("pilot_lastname");
        this.contactForm.removeControl("pilot_phone");
        break;
      case "test-drive":
        this.contactForm.addControl(
          "pilot_car_modelo",
          this.vehicleModelControl
        );
        this.contactForm.addControl(
          "pilot_best_contact_time",
          this.dateControl
        );
        break;
      case "posventa":
        this.contactForm.addControl(
          "pilot_car_modelo",
          this.vehicleModelControl
        );
        this.contactForm.addControl("year", this.vehicleYearControl);
        this.contactForm.addControl(
          "pilot_best_contact_time",
          this.dateControl
        );
        this.contactForm.addControl("patente", this.platesControl);
        this.contactForm.addControl("campania", this.campaniaControl);
        break;

      default:
      case "contacto":
        this.contactForm.addControl("pilot_notes", this.commentsControl);
        this.contactForm.addControl("subject", this.subjectControl);
        break;
    }
  }

  submitForm() {
    this.overlayService.create();

    const configParams = {
      pilot_contact_type_id: 1,
      pilot_suborigin_id: "EBMH16K20OYVBCS2S",
      pilot_tracking_id: new Date().getTime(),
    };

    let formValue = { ...this.contactForm.value };
    const asunto = this.asuntos.find((a) => a.value === this.subject);

    if (!asunto) {
      this.isError = true;
      this.message = `Debe completar el asunto`;
      this.overlayService.dismiss();
      return;
    }
    if (asunto.pilot_origin) {
      formValue = Object.assign(formValue, configParams);
      formValue.pilot_suborigin_id =
        asunto.pilot_origin || configParams.pilot_suborigin_id;
    }

    if (formValue.dni) {
      formValue.pilot_client_identity_document = `DNI: ${formValue.dni}`;
    }

    if (formValue.pilot_car_modelo) {
      if (formValue["vehicle-version"]) {
        formValue.pilot_car_modelo = `${formValue.pilot_car_modelo} (MODELO: ${formValue["vehicle-version"]})`;
      }

      if (formValue.year) {
        formValue.pilot_car_modelo = `${formValue.pilot_car_modelo} (AÑO: ${formValue["year"]})`;
      }

      if (formValue.patente) {
        formValue.pilot_car_modelo = `${formValue.pilot_car_modelo} (PATENTE: ${formValue["patente"]})`;
      }
    }

    if (formValue.subject) {
      formValue.pilot_notes = `ASUNTO: ${formValue.subject} - ${formValue.pilot_notes}`;
    }

    if (formValue.pilot_best_contact_time) {
      formValue.pilot_best_contact_time =
        formValue.pilot_best_contact_time.toLocaleDateString("es-AR");
    }

    delete formValue.dni;
    formValue["vehicle-version"] && delete formValue["vehicle-version"];
    formValue["pilot_business_type_id"] =
      formValue["pilot_business_type_id"] || 1;

    this.makeRequest(environment.wbsuite, formValue, asunto.mailTo);
  }

  makeRequest(config, form, mailTo) {
    return this.httpClient
      .post(
        `${config.api}/leads/${config.campaingId}`,
        { ...form, mailTo: mailTo },
        {
          headers: new HttpHeaders()
            .append("Content-type", "application/json")
            .append("Authorization", `ApiKey ${config.apiKey}`),
        }
      )
      .subscribe(
        (result: any) => {
          if (result.success) {
            this.isError = false;
            this.message = `Se ha enviado su consulta correctamente. La/Lo contactaremos a la brevedad.`;
            this.overlayService.dismiss();
          }
        },
        (err) => {
          if (config.backup) {
            this.makeRequest(config.backup, form, mailTo);
            return;
          }
          this.isError = true;
          this.message = `Ha ocurrido un error mientras se enviaba su consulta. Por favor intentelo de nuevo 
        o contáctenos a cualquiera de nuestras sucursales: 4002 0000 (Quilmes), 4116 6600 (Sarandí) o 3221 9118 (Varela).`;
          this.overlayService.dismiss();
        }
      );
  }

  captchaSuccess(result) {
    this.ngZone.run(() => {
      this.captchaValidated.setValue("success");
      this.captchaValidated.updateValueAndValidity();
    });
  }

  captchaExpire() {
    this.ngZone.run(() => {
      this.captchaValidated.setValue("");
      this.captchaValidated.updateValueAndValidity();
    });
  }
}

import { Injectable } from "@angular/core";
import { CarEnum } from "../enums/car.enum";

interface Color {
  name: string;
  value: string;
}

interface Image {
  src: string;
  colSpan?: number;
  rowSpan?: number;
}

interface Specs {
  generales: any[];
  motorizacion: any[];
  exterior: any[];
  interior: any[];
  entretenimiento: any[];
  seguridad: any[];
}

export interface CarI {
  order: number;
  imgsrc: string;
  name: string;
  link: string;
  colors: Color[];
  gallery: Image[];
  versiones?: string[];
  hasPlanOvalo?: boolean;
  planOvaloPrice?: number;
  showFichaTecnica?: boolean;
  welcomeText?: string;
  specs?: Specs;
  fichaTecnica?: string;
  galleryButton?: { colSpan?: number; rowSpan?: number };
}

@Injectable({
  providedIn: "root"
})
export class CarService {
  constructor() {}

  public getCars() {
    return CarEnum.sort((c1, c2) => c1.order - c2.order);
  }

  public getPlanOvaloCars() {
    return CarEnum.filter(car => car.hasPlanOvalo);
  }
}

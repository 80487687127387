import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-oportunidades",
  templateUrl: "./oportunidades.component.html",
  styleUrls: ["./oportunidades.component.scss"]
})
export class OportunidadesComponent implements OnInit {
  @Input() oportunidades: any[] = [
    "oportunidadDescuentoPosventa",
    "oportunidadDescuentoSeguro",
    "oportunidadEmpresas",
    "oportunidadTestDrive",
    "oportunidadTestDriveCTA",
    "oportunidadUsados"
  ];

  constructor() {}

  ngOnInit() {}
}

import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnInit,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  selector: "[appCreateComponent]",
})
export class CreateComponentDirective implements OnInit {
  @Input("appCreateComponent") slide;

  constructor(
    private elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const component = this.slide.component;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef =
      this.viewContainerRef.createComponent(componentFactory);

    (componentRef.instance as any).slide = this.slide;
  }
}

import { Directive, HostBinding } from "@angular/core";
import { OverlayService } from "../services/overlay.service";

@Directive({
  selector: "[appOverlay]"
})
export class OverlayDirective {
  @HostBinding("class.overlay") showOverlay;

  constructor(private overlayService: OverlayService) {
    this.overlayService.showOverlay$.subscribe(showOverlay => {
      this.showOverlay = showOverlay;
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-nuevos",
  templateUrl: "./nuevos.component.html",
  styleUrls: ["./nuevos.component.scss"],
})
export class NuevosComponent implements OnInit {
  CONTACTO = CONTACTOS["0km"];
  slides = [
    {
      src: "assets/imgs/0-km/4.jpg",
      page: ["", "modelos", "ranger"],
    },
    {
      src: "assets/imgs/0-km/3.jpg",
      page: ["", "modelos", "ranger-raptor"],
    },
    {
      src: "assets/imgs/0-km/1.jpg",
      page: ["", "modelos", "f-150-raptor"],
    },
    {
      src: "assets/imgs/0-km/2.jpg",
      page: ["", "modelos", "f-150"],
    },
  ];

  constructor() {}

  ngOnInit() {}
}

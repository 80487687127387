import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slide-with-text",
  templateUrl: "./slide-with-text.component.html",
  styleUrls: ["./slide-with-text.component.scss"],
})
export class SlideWithTextComponent implements OnInit {
  public slide;

  constructor() {}

  ngOnInit() {}
}

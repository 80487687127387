import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class OverlayService {
  private overlaySubject = new BehaviorSubject(false);
  public showOverlay$ = this.overlaySubject.asObservable();

  constructor() {}

  create() {
    this.overlaySubject.next(true);
  }

  dismiss() {
    this.overlaySubject.next(false);
  }
}

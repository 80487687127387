import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-main-slider",
  templateUrl: "./main-slider.component.html",
  styleUrls: ["./main-slider.component.scss"],
})
export class MainSliderComponent implements OnInit {
  currentSlide = 1;
  pages = [1, 1, 1, 1, 1];

  constructor() {}

  ngOnInit() {}

  next() {
    if (this.currentSlide < this.pages.length) {
      this.currentSlide++;
    }
  }

  prev() {
    if (this.currentSlide > 1) {
      this.currentSlide--;
    }
  }
}

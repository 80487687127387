import { Component, OnInit, Input } from "@angular/core";
import { CarService } from "src/app/services/car.services";

@Component({
  selector: "app-main-car-slider",
  templateUrl: "./main-car-slider.component.html",
  styleUrls: ["./main-car-slider.component.scss"]
})
export class MainCarSliderComponent implements OnInit {
  @Input() autoplan = false;
  currentSlide = 1;
  pageSize = 3;
  pages = [];

  constructor(private carService: CarService) {
    this.calculatePageSize();
    window.onresize = this.calculatePageSize;
  }

  calculatePageSize() {
    if (window.innerWidth < 768) {
      this.pageSize = 1;
    } else {
      this.pageSize = 3;
    }
  }

  ngOnInit() {
    this.pages = this.splitCarsInPages();
  }

  next() {
    if (this.currentSlide < this.pages.length) {
      this.currentSlide++;
    }
  }

  prev() {
    if (this.currentSlide > 1) {
      this.currentSlide--;
    }
  }

  getCars(): any[] {
    if (this.autoplan) {
      return this.carService.getPlanOvaloCars();
    }
    return this.carService.getCars();
  }

  splitCarsInPages() {
    const cars = [...this.getCars()];
    const carAmount = cars.length;

    const pagesAmount = Math.ceil(carAmount / this.pageSize);
    const pages = [];
    for (let j = 0; j < pagesAmount; j++) {
      let page = [];
      for (let i = 0; i < this.pageSize && cars.length > 0; i++) {
        page.push(cars.shift());
      }
      pages.push(page);
    }
    return pages;
  }
}

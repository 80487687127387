import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { LogoComponent } from "./components/logo/logo.component";
import { ContainerComponent } from "./components/container/container.component";
import { MainSliderComponent } from "./components/main-slider/main-slider.component";
import { SectionTitleComponent } from "./components/section-title/section-title.component";
import { CtaButtonComponent } from "./components/cta-button/cta-button.component";
import { OportunidadesComponent } from "./components/oportunidades/oportunidades.component";
import { BranchesComponent } from "./components/branches/branches.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatIconModule,
  MatSelectModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule,
} from "@angular/material";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";
import { MainCarSliderComponent } from "./components/main-car-slider/main-car-slider.component";
import { CarModelComponent } from "./pages/car-model/car-model.component";
import { CarCatalogComponent } from "./pages/car-catalog/car-catalog.component";
import { PlanOvaloComponent } from "./pages/plan-ovalo/plan-ovalo.component";
import { PosventaComponent } from "./pages/posventa/posventa.component";
import { WpTextLinkComponent } from "./components/wp-text-link/wp-text-link.component";
import { PreFooterComponent } from "./components/pre-footer/pre-footer.component";
import { CarPortraitComponent } from "./components/car-portrait/car-portrait.component";
import { ContactoComponent } from "./pages/contacto/contacto.component";
import { CarListComponent } from "./components/car-list/car-list.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SliderComponent } from "./components/slider/slider.component";
import { NuevosComponent } from "./pages/nuevos/nuevos.component";
import { UsadosComponent } from "./pages/usados/usados.component";
import { CorporativosComponent } from "./pages/corporativos/corporativos.component";
import { OportunidadesPageComponent } from "./pages/oportunidades-page/oportunidades-page.component";
import { NosotrosComponent } from "./pages/nosotros/nosotros.component";
import { FordPassComponent } from "./components/ford-pass/ford-pass.component";
import { OverlayDirective } from "./directives/overlay.directive";
import { HttpClientModule } from "@angular/common/http";
import { WpButtonComponent } from "./components/wp-button/wp-button.component";
import { LaCajaComponent } from "./components/la-caja/la-caja.component";
import { ToastContainerModule, ToastrModule } from "ngx-toastr";
import { CreateComponentDirective } from "./directives/create-component.directive";
import { SlideWithTextComponent } from "./components/slides/slide-with-text/slide-with-text.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    LogoComponent,
    ContainerComponent,
    MainSliderComponent,
    SectionTitleComponent,
    CtaButtonComponent,
    OportunidadesComponent,
    BranchesComponent,
    MainCarSliderComponent,
    CarModelComponent,
    CarCatalogComponent,
    PlanOvaloComponent,
    PosventaComponent,
    WpTextLinkComponent,
    PreFooterComponent,
    CarPortraitComponent,
    ContactoComponent,
    CarListComponent,
    SliderComponent,
    NuevosComponent,
    UsadosComponent,
    CorporativosComponent,
    OportunidadesPageComponent,
    NosotrosComponent,
    FordPassComponent,
    OverlayDirective,
    WpButtonComponent,
    LaCajaComponent,
    CreateComponentDirective,
    SlideWithTextComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastContainerModule,
    ToastrModule.forRoot({ positionClass: "toast-bottom-left" }),
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [SlideWithTextComponent],
})
export class AppModule {
  constructor() { }
}

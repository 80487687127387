import { Component, OnInit } from "@angular/core";
import { SlideWithTextComponent } from "src/app/components/slides/slide-with-text/slide-with-text.component";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-posventa",
  templateUrl: "./posventa.component.html",
  styleUrls: ["./posventa.component.scss"],
})
export class PosventaComponent implements OnInit {
  POSVENTA = CONTACTOS.posventa;
  slides = [
    {
      src: "assets/imgs/posventa/1.jpg",
      position: "bottom",
    },
  ];

  constructor() {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from "@angular/core";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-wp-text-link",
  templateUrl: "./wp-text-link.component.html",
  styleUrls: ["./wp-text-link.component.scss"],
})
export class WpTextLinkComponent implements OnInit {
  @Input() wpNumber = CONTACTOS.wpGenerico;

  constructor() {}

  ngOnInit() {}
}

import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { CarService } from "src/app/services/car.services";

@Injectable({
  providedIn: "root",
})
export class ModelExistsGuard implements CanActivate {
  constructor(
    private carService: CarService,
    private router: Router,
    private toastrService: ToastrService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { model } = next.params;
    const { modalidad } = next.queryParams;
    const carExists = !!this.carService
      .getCars()
      .find((car) => car.link === model);
    if (!carExists) {
      this.toastrService.error("El modelo que estas buscando no existe.");
      if (modalidad === "plan-ovalo") {
        this.router.navigate(["plan-ovalo"]);
      } else if (modalidad === "0-km") {
        this.router.navigate(["0-km"]);
      } else {
        this.router.navigate(["inicio"]);
      }
    }
    return carExists;
  }
}

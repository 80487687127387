import { Component, OnInit } from "@angular/core";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  POSVENTA = CONTACTOS.posventa;

  constructor() {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from "@angular/core";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() whatsapp = true;
  wpItems = [
    {
      name: "Chat Bot",
      number: CONTACTOS.wpGenerico,
      message: "Hola! Quería consultar por",
    },
  ];

  constructor() {}

  ngOnInit() {}
}

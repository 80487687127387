import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ford-pass',
  templateUrl: './ford-pass.component.html',
  styleUrls: ['./ford-pass.component.scss']
})
export class FordPassComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

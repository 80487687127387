import { CarI } from "src/app/services/car.services";

export const CarEnum: CarI[] = [
  // {
  //   order: 1,
  //   imgsrc: "assets/imgs/car-slides/nuevo-ka.png",
  //   name: "Nuevo Ka",
  //   link: "nuevo-ka",
  //   colors: [
  //     { name: "Rojo", value: "red" },
  //     { name: "Azul", value: "blue" },
  //   ],
  //   hasPlanOvalo: true,
  //   planOvaloPrice: 11417,
  //   gallery: [
  //     {
  //       src: "1.jpg",
  //       colSpan: 2,
  //       rowSpan: 1,
  //     },
  //     { src: "2.jpg" },
  //     { src: "5.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg" },
  //     { src: "3.jpg" },
  //   ],
  //   versiones: ["S", "SE", "SE AT", "SEL", "SEL AT"],
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/ka/pdf/far-nuevo-ka-ficha-tecnica.pdf",
  //   specs: {
  //     generales: [
  //       {
  //         name: "Largo (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Ancho con espejos (mm)",
  //         val: 3941,
  //         // S: 3941,
  //         // SE: 3941,
  //         // "SE AT": 3941,
  //         // SEL: 3941,
  //         // "SEL AT": 3941
  //       },
  //       {
  //         name: "Altura (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Distancia entre ejes (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: 'Llantas de chapa de 14" (mm)',
  //         S: "Si",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de chapa de 15" (mm)',
  //         S: "-",
  //         SE: "Si",
  //         "SE AT": "Si",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de aleación de aluminio - 15" (mm)',
  //         S: "-",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "Si",
  //         "SEL AT": "Si",
  //       },
  //       {
  //         name: "Neumáticos",
  //         val: "Pirelli",
  //       },
  //       {
  //         name: "Rueda de Auxilio",
  //         val: "Si",
  //       },
  //       {
  //         name: "Dirección",
  //         val: "Asistida",
  //       },
  //       {
  //         name: "Frenos",
  //         val: "ABS",
  //       },
  //     ],
  //     motorizacion: [
  //       {
  //         name: "Largo (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Ancho con espejos (mm)",
  //         val: 3941,
  //         // S: 3941,
  //         // SE: 3941,
  //         // "SE AT": 3941,
  //         // SEL: 3941,
  //         // "SEL AT": 3941
  //       },
  //       {
  //         name: "Altura (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Distancia entre ejes (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: 'Llantas de chapa de 14" (mm)',
  //         S: "Si",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de chapa de 15" (mm)',
  //         S: "-",
  //         SE: "Si",
  //         "SE AT": "Si",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de aleación de aluminio - 15" (mm)',
  //         S: "-",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "Si",
  //         "SEL AT": "Si",
  //       },
  //       {
  //         name: "Neumáticos",
  //         val: "Pirelli",
  //       },
  //       {
  //         name: "Rueda de Auxilio",
  //         val: "Si",
  //       },
  //       {
  //         name: "Dirección",
  //         val: "Asistida",
  //       },
  //       {
  //         name: "Frenos",
  //         val: "ABS",
  //       },
  //     ],
  //     exterior: [
  //       {
  //         name: "Largo (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Ancho con espejos (mm)",
  //         val: 3941,
  //         // S: 3941,
  //         // SE: 3941,
  //         // "SE AT": 3941,
  //         // SEL: 3941,
  //         // "SEL AT": 3941
  //       },
  //       {
  //         name: "Altura (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Distancia entre ejes (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: 'Llantas de chapa de 14" (mm)',
  //         S: "Si",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de chapa de 15" (mm)',
  //         S: "-",
  //         SE: "Si",
  //         "SE AT": "Si",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de aleación de aluminio - 15" (mm)',
  //         S: "-",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "Si",
  //         "SEL AT": "Si",
  //       },
  //       {
  //         name: "Neumáticos",
  //         val: "Pirelli",
  //       },
  //       {
  //         name: "Rueda de Auxilio",
  //         val: "Si",
  //       },
  //       {
  //         name: "Dirección",
  //         val: "Asistida",
  //       },
  //       {
  //         name: "Frenos",
  //         val: "ABS",
  //       },
  //     ],
  //     interior: [
  //       {
  //         name: "Largo (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Ancho con espejos (mm)",
  //         val: 3941,
  //         // S: 3941,
  //         // SE: 3941,
  //         // "SE AT": 3941,
  //         // SEL: 3941,
  //         // "SEL AT": 3941
  //       },
  //       {
  //         name: "Altura (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Distancia entre ejes (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: 'Llantas de chapa de 14" (mm)',
  //         S: "Si",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de chapa de 15" (mm)',
  //         S: "-",
  //         SE: "Si",
  //         "SE AT": "Si",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de aleación de aluminio - 15" (mm)',
  //         S: "-",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "Si",
  //         "SEL AT": "Si",
  //       },
  //       {
  //         name: "Neumáticos",
  //         val: "Pirelli",
  //       },
  //       {
  //         name: "Rueda de Auxilio",
  //         val: "Si",
  //       },
  //       {
  //         name: "Dirección",
  //         val: "Asistida",
  //       },
  //       {
  //         name: "Frenos",
  //         val: "ABS",
  //       },
  //     ],
  //     entretenimiento: [
  //       {
  //         name: "Largo (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Ancho con espejos (mm)",
  //         val: 3941,
  //         // S: 3941,
  //         // SE: 3941,
  //         // "SE AT": 3941,
  //         // SEL: 3941,
  //         // "SEL AT": 3941
  //       },
  //       {
  //         name: "Altura (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Distancia entre ejes (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: 'Llantas de chapa de 14" (mm)',
  //         S: "Si",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de chapa de 15" (mm)',
  //         S: "-",
  //         SE: "Si",
  //         "SE AT": "Si",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de aleación de aluminio - 15" (mm)',
  //         S: "-",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "Si",
  //         "SEL AT": "Si",
  //       },
  //       {
  //         name: "Neumáticos",
  //         val: "Pirelli",
  //       },
  //       {
  //         name: "Rueda de Auxilio",
  //         val: "Si",
  //       },
  //       {
  //         name: "Dirección",
  //         val: "Asistida",
  //       },
  //       {
  //         name: "Frenos",
  //         val: "ABS",
  //       },
  //     ],
  //     seguridad: [
  //       {
  //         name: "Largo (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Ancho con espejos (mm)",
  //         val: 3941,
  //         // S: 3941,
  //         // SE: 3941,
  //         // "SE AT": 3941,
  //         // SEL: 3941,
  //         // "SEL AT": 3941
  //       },
  //       {
  //         name: "Altura (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: "Distancia entre ejes (mm)",
  //         val: 3941,
  //       },
  //       {
  //         name: 'Llantas de chapa de 14" (mm)',
  //         S: "Si",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de chapa de 15" (mm)',
  //         S: "-",
  //         SE: "Si",
  //         "SE AT": "Si",
  //         SEL: "-",
  //         "SEL AT": "-",
  //       },
  //       {
  //         name: 'Llantas de aleación de aluminio - 15" (mm)',
  //         S: "-",
  //         SE: "-",
  //         "SE AT": "-",
  //         SEL: "Si",
  //         "SEL AT": "Si",
  //       },
  //       {
  //         name: "Neumáticos",
  //         val: "Pirelli",
  //       },
  //       {
  //         name: "Rueda de Auxilio",
  //         val: "Si",
  //       },
  //       {
  //         name: "Dirección",
  //         val: "Asistida",
  //       },
  //       {
  //         name: "Frenos",
  //         val: "ABS",
  //       },
  //     ],
  //   },
  // },
  // {
  //   order: 2,
  //   imgsrc: "assets/imgs/car-slides/ka-plus.jpg",
  //   name: "ka+",
  //   colors: [
  //     { name: "rojo", value: "red" },
  //     { value: "#fafafa", name: "blanco oxford" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "gris ocre", value: "#71655C" },
  //     { name: "negro perlado", value: "#3C4041" },
  //     { name: "plata estelar", value: "#A8A7AC" },
  //   ],
  //   gallery: [
  //     {
  //       src: "2.jpg",
  //       colSpan: 1,
  //       rowSpan: 1,
  //     },
  //     { src: "1.jpg" },
  //     { src: "3.jpg" },
  //   ],
  //   link: "ka-plus",
  //   hasPlanOvalo: true,
  //   versiones: ["S", "SE", "SE AT", "SEL", "SEL AT"],
  //   planOvaloPrice: 11762,
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  // {
  //   order: 3,
  //   imgsrc: "assets/imgs/car-slides/ka-freestyle.png",
  //   name: "ka freestyle",
  //   link: "ka-freestyle",
  //   colors: [
  //     { name: "cobre andino", value: "red" },
  //     { name: "blanco artico", value: "#fafafa" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "gris ocre", value: "#71655C" },
  //     { name: "negro perlado", value: "#3C4041" },
  //     { name: "plata estelar", value: "#A8A7AC" },
  //   ],
  //   gallery: [
  //     {
  //       src: "1.jpg",
  //       colSpan: 1,
  //       rowSpan: 1,
  //     },
  //     { src: "2.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "3.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg" },
  //     { src: "6.jpg", colSpan: 2 },
  //     { src: "7.jpg" },
  //   ],
  //   versiones: ["SE MT", "SEL MT", "SEL AT"],
  //   hasPlanOvalo: true,
  //   planOvaloPrice: 14524,
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  // {
  //   order: 4,
  //   imgsrc: "assets/imgs/car-slides/fiesta.png",
  //   name: "fiesta",
  //   link: "fiesta",
  //   gallery: [
  //     { src: "1.jpg" },
  //     { src: "2.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "3.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg" },
  //     { src: "6.jpg" },
  //     { src: "7.jpg" },
  //     { src: "8.jpg" },
  //   ],
  //   versiones: [
  //     "S Plus",
  //     "SE",
  //     "SE AT",
  //     "SE Plus",
  //     "SE Plus AT",
  //     "Titanium",
  //     "Titanium AT",
  //   ],
  //   colors: [
  //     { name: "azul mediterraneo", value: "#356CAF" },
  //     { name: "blanco oxford", value: "#fafafa" },
  //     { name: "gris plata", value: "#6C7371" },
  //     { name: "negro perlado", value: "#3C4041" },
  //     { name: "rojo sport", value: "red" },
  //     { name: "rojo rubi", value: "#AA1E26" },
  //   ],
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/nuevo-fiesta/pdf/far-fiesta-ficha-tec-junio-2018.pdf",
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  // {
  //   order: 5,
  //   imgsrc: "assets/imgs/car-slides/focus.jpg",
  //   name: "focus",
  //   link: "focus",
  //   gallery: [
  //     { src: "2.jpeg" },
  //     { src: "4.jpeg" },
  //     { src: "3.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "1.jpeg", colSpan: 2 },
  //     { src: "5.jpeg" },
  //     { src: "6.jpeg" },
  //   ],
  //   galleryButton: { colSpan: 2, rowSpan: 1 },
  //   versiones: [
  //     "S",
  //     "SE",
  //     "SE AT",
  //     "SE Plus",
  //     "SE Plus AT",
  //     "Titanium",
  //     "Titanium AT",
  //   ],
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/focus/far-focus-ficha-tecnica-junio-2018.pdf",
  //   colors: [
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "plata metalizado", value: "#A4A3A8" },
  //     { name: "gris mercurio", value: "#797E7F" },
  //     { name: "azul aurora", value: "#2A3374" },
  //     { name: "blanco oxford", value: "#fafafa" },
  //     { name: "negro perlado", value: "#3C4041" },
  //     { name: "rojo bari", value: "red" },
  //     { name: "rojo cafayate", value: "#AA1E26" },
  //     { name: "gris tectonic", value: "#AA1E26" },
  //   ],
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  // {
  //   order: 6,
  //   imgsrc: "assets/imgs/car-slides/focus-4p.png",
  //   name: "focus 4 puertas",
  //   link: "focus-4p",
  //   gallery: [
  //     { src: "1.jpg" },
  //     { src: "2.jpg" },
  //     { src: "3.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "6.jpg" },
  //     { src: "7.jpg" },
  //   ],
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/focus-sedan/far-focus4p-ficha-tecnica-junio-2018.pdf",
  //   colors: [
  //     { name: "plata metalizado", value: "#A4A3A8" },
  //     { name: "gris mercurio", value: "#797E7F" },
  //     { name: "azul aurora", value: "#2A3374" },
  //     { name: "blanco oxford", value: "#fafafa" },
  //     { name: "negro perlado", value: "#3C4041" },
  //     { name: "rojo bari", value: "red" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "gris tectonic", value: "#AA1E26" },
  //   ],
  //   galleryButton: { colSpan: 2, rowSpan: 1 },
  //   versiones: [
  //     "S",
  //     "SE",
  //     "SE AT",
  //     "SE Plus",
  //     "SE Plus AT",
  //     "Titanium",
  //     "Titanium AT",
  //   ],
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  // {
  //   order: 7,
  //   imgsrc: "assets/imgs/car-slides/mondeo.png",
  //   name: "mondeo",
  //   link: "mondeo",
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/nuevo_mondeo/2019/ficha-tecnica/far-nuevo-mondeo-ficha-tecnica.pdf",
  //   gallery: [
  //     { src: "1.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "3.jpg", colSpan: 2 },
  //     { src: "2.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg" },
  //     { src: "6.jpg" },
  //   ],
  //   colors: [
  //     { name: "blanco platino", value: "#fafafa" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "gris plata", value: "#A4A3A8" },
  //     { name: "negro ebony", value: "#3C4041" },
  //     { name: "azul metalico", value: "#2A3374" },
  //   ],
  //   galleryButton: { colSpan: 2, rowSpan: 1 },
  //   versiones: ["SEL", "Titanium"],
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },

  // {
  //   order: 8,
  //   imgsrc: "assets/imgs/car-slides/mondeo-vignale.png",
  //   name: "mondeo vignale híbrido",
  //   link: "mondeo-vignale",
  //   gallery: [
  //     { src: "1.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "3.jpg", colSpan: 2 },
  //     { src: "2.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg" },
  //     { src: "6.jpg" },
  //   ],
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/mondeo_vignale_hibrido/pdf/far-new-mondeo-hibrido-ficha-tecnica.pdf",
  //   colors: [
  //     { name: "blanco platino", value: "#fafafa" },
  //     { name: "negro ebony", value: "#3C4041" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "azul metalico", value: "#2A3374" },
  //   ],
  //   galleryButton: { colSpan: 2, rowSpan: 1 },
  //   versiones: ["SEL", "Titanium"],
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  {
    order: 9,
    imgsrc: "assets/imgs/car-slides/mache.png",
    name: "Mach-E",
    link: "mache",
    gallery: [
      { src: "mustang 1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "mustang 3.jpg", colSpan: 2 },
      { src: "mustang 2.jpg" },
      { src: "mustang 4.jpg" },
      { src: "mustang 5.jpg", colSpan: 2, rowSpan: 2 },
    ],
    colors: [
      { name: "blanco estrella", value: "#fafafa" },
      { name: "gris mercurio", value: "#57524B" },
      { name: "azúl vapor", value: "#6D7F8E" },
      { name: "azúl eléctrico", value: "#0885D9" },
    ],
    galleryButton: { colSpan: 2, rowSpan: 1 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/mustang-mach-e/pdf/far-mustang-mach-e-ficha-tec-v2.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  // {
  //   order: 9,
  //   imgsrc: "assets/imgs/car-slides/mustang.jpg",
  //   name: "mustang",
  //   link: "mustang",
  //   gallery: [
  //     { src: "1.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "3.jpg", colSpan: 2 },
  //     { src: "2.jpg" },
  //     { src: "4.jpg" },
  //     { src: "4.jpg" },
  //     { src: "5.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "6.jpg" },
  //     { src: "7.jpg" },
  //   ],
  //   colors: [
  //     { name: "blanco platino", value: "#fafafa" },
  //     { name: "negro ebony", value: "#3C4041" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "azul metalico", value: "#2A3374" },
  //   ],
  //   galleryButton: { colSpan: 1, rowSpan: 1 },
  //   versiones: ["GT"],
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/mustang/mustang-2020/pdf/far-nuevo-mustang-ficha-tecnica.pdf",
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  {
    order: 11,
    imgsrc: "assets/imgs/car-slides/kuga-hibrida.png",
    name: "kuga híbrida",
    link: "kuga",
    gallery: [
      { src: "1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "3.jpg", colSpan: 2 },
      { src: "2.jpg" },
      { src: "6.jpg" },
      { src: "5.jpg" },
      { src: "7.jpg" },
      { src: "4.jpg", colSpan: 2, rowSpan: 2 },
    ],
    colors: [],
    galleryButton: { colSpan: 2, rowSpan: 1 },
    versiones: ["SE", "Titanium"],
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/kuga-hibrido/2020/brochure/far-nueva-kuga-hibrida-ficha-tecnica.pdf?dtlls-hdr-fchtcnc",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },

  // {
  //   order: 10,
  //   imgsrc: "assets/imgs/car-slides/ecosport.png",
  //   name: "ecosport",
  //   link: "ecosport",
  //   // planOvaloPrice: 13276,
  //   gallery: [
  //     { src: "1.jpg" },
  //     { src: "2.jpg" },
  //     { src: "3.jpg", colSpan: 2, rowSpan: 2 },
  //     { src: "4.jpg" },
  //     { src: "5.jpg" },
  //     { src: "6.jpg" },
  //     { src: "7.jpg" },
  //   ],
  //   galleryButton: { colSpan: 2 },
  //   colors: [
  //     { name: "blanco platino", value: "#fafafa" },
  //     { name: "negro ebony", value: "#3C4041" },
  //     { name: "gris magnetic", value: "#6C7371" },
  //     { name: "azul metalico", value: "#2A3374" },
  //   ],
  //   versiones: [
  //     "S 1.5L Dragon",
  //     "SE 1.5L Dragon MT",
  //     "SE 1.5L Dragon AT",
  //     "Freestyle 1.5L Dragon MT",
  //     "Titanium 1.5L Dragon AT",
  //     "Titanium 1.5L Dragon AT",
  //     "Titanium 2.0L Duratec GDI AT",
  //     "Storm 2.0L Duratec 4x4 AT",
  //   ],
  //   showFichaTecnica: false,
  //   fichaTecnica:
  //     "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/ecosport/2021/overview/pdf/far-ecosport-ficha-tecnica.pdf",
  //   specs: {
  //     generales: [],
  //     motorizacion: [],
  //     exterior: [],
  //     interior: [],
  //     entretenimiento: [],
  //     seguridad: [],
  //   },
  // },
  {
    order: 1,
    imgsrc: "assets/imgs/car-slides/ranger.png",
    name: "ranger",
    link: "ranger",
    hasPlanOvalo: true,
    // planOvaloPrice: 31048,
    gallery: [
      { src: "1.jpg", colSpan: 2 },
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "5.jpg" },
      { src: "2.jpg", colSpan: 3, rowSpan: 1 },
      { src: "6.jpg" },
      { src: "7.jpg" },
    ],
    galleryButton: { colSpan: 2 },
    colors: [
      { name: "blanco oxford", value: "#fafafa" },
      { name: "plata metalizado", value: "#A4A3A8" },
      { name: "negro perlado", value: "#3C4041" },
      { name: "azul belice", value: "#2A3374" },
      { name: "gris magnetic", value: "#6C7371" },
      { name: "rojo bari", value: "red" },
      { name: "cafayate", value: "darkred" },
    ],
    versiones: [],
    showFichaTecnica: false,
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/Nueva-ranger/2020/pdf/far-ranger-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 2,
    imgsrc: "assets/imgs/car-slides/ranger-raptor.jpg",
    name: "ranger raptor",
    link: "ranger-raptor",
    welcomeText: "welcome-text.png",
    gallery: [
      { src: "1.jpg" },
      { src: "3.jpg", colSpan: 2, rowSpan: 2 },
      { src: "4.jpg" },
      { src: "2.jpg" },
      { src: "6.jpg" },
      { src: "5.jpg", colSpan: 2 },
    ],
    galleryButton: { colSpan: 2 },
    colors: [
      { name: "blanco ártico", value: "#fafafa" },
      { name: "negro ebony", value: "#3C4041" },
      { name: "azul belice", value: "#2A3374" },
    ],
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 4,
    imgsrc: "assets/imgs/car-slides/f150.png",
    colors: [
      { name: "rojo racing", value: "red" },
      { name: "blanco metalico", value: "#fafafa" },
      { name: "negro ebony", value: "#000" },
    ],
    gallery: [
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "2.jpg", colSpan: 1 },
      { src: "5.jpg", colSpan: 1 },
    ],
    galleryButton: { colSpan: 4 },
    name: "f-150",
    link: "f-150",
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/f-150/2020/brochure/far-nueva-f-150-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 5,
    imgsrc: "assets/imgs/car-slides/f-150-raptor.png",
    name: "f-150 raptor",
    link: "f-150-raptor",
    colors: [{ name: "Blanco oxford", value: "#fafafa" }],
    gallery: [
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "2.jpg", colSpan: 2 },
      { src: "5.jpg", colSpan: 1 },
      { src: "6.jpg", colSpan: 1 },
    ],
    welcomeText: "welcome-text.png",
    galleryButton: { colSpan: 2 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/f-150-raptor/2020/brochure/far-nueva-f-150-raptor-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 16,
    imgsrc: "assets/imgs/car-slides/transit.jpg",
    colors: [{ name: "blanco oxford", value: "#fafafa" }],
    gallery: [
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "2.jpg", colSpan: 1 },
      { src: "5.jpg", colSpan: 1 },
    ],
    galleryButton: { colSpan: 4 },
    name: "transit",
    link: "transit",
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/transit-van-mediana/2018/pdf/far-transit-van-2019-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 17,
    imgsrc: "assets/imgs/car-slides/transit-minibus.jpg",
    name: "transit minibus",
    link: "transit-minibus",
    colors: [{ name: "Blanco oxford", value: "#fafafa" }],
    gallery: [
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "2.jpeg", colSpan: 2 },
      { src: "5.jpg", colSpan: 2 },
    ],
    galleryButton: { colSpan: 2 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/transit-minibus/2018/pdf/far-transit-minibus-2019-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 18,
    imgsrc: "assets/imgs/car-slides/transit-chasis.png",
    name: "transit chasis",
    link: "transit-chasis",
    colors: [
      { name: "Blanco oxford", value: "#fafafa" },
      { name: "plata lunar", value: "#A4A3A8" },
    ],
    gallery: [
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "2.jpeg", colSpan: 2 },
      { src: "5.jpg", colSpan: 2 },
    ],
    galleryButton: { colSpan: 2 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/transit-chasis/transit-pdf/far-transit-chasis-2019-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 5,
    imgsrc: "assets/imgs/car-slides/territory.png",
    name: "territory",
    link: "territory",
    hasPlanOvalo: true,
    colors: [],
    gallery: [
      { src: "1.png" },
      { src: "2.jpg" },
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "6.jpg" },
      { src: "7.jpg" },
      { src: "8.jpeg" },
      { src: "9.jpg" },
    ],
    galleryButton: { colSpan: 4 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/territory/brochure/far-territory-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 6,
    imgsrc: "assets/imgs/car-slides/bronco.png",
    name: "bronco",
    link: "bronco",
    hasPlanOvalo: false,
    colors: [],
    gallery: [
      { src: "1.jpg" },
      { src: "2.jpg" },
      { src: "3.jpg" },
      { src: "4.jpg" },
      { src: "5.jpg" },
    ],
    galleryButton: { colSpan: 4 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/bronco-sport/prelaunch/pdf/far-bronco-sport-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 7,
    imgsrc: "assets/imgs/car-slides/nueva-bronco.png",
    name: "Nueva Bronco",
    link: "nueva-bronco",
    hasPlanOvalo: false,
    colors: [],
    gallery: [
      { src: "bronco1.jpg", colSpan: 2, rowSpan: 2 },
      { src: "bronco2.jpg" },
      { src: "bronco3.jpg" },
      { src: "bronco4.jpg" },
      { src: "bronco5.jpg" },
    ],
    galleryButton: { colSpan: 4 },
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/nueva-bronco/pdf/far-nueva-bronco-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
  {
    order: 3,
    imgsrc: "assets/imgs/car-slides/maverick.png",
    name: "maverick",
    link: "maverick",
    colors: [],
    gallery: [
      { src: "3.jpg", colSpan: 2, rowSpan: 2 },
      { src: "1.jpg" },
      { src: "2.jpg" },
      { src: "4.jpg" },
      { src: "5.jpg" },
      { src: "6.jpg" },
      { src: "7.jpg" },
      { src: "13.jpg", colSpan: 2, rowSpan: 2 },
      { src: "8.jpg" },
      { src: "9.jpg" },
      { src: "10.jpg" },
      { src: "11.jpg" },
      { src: "12.jpg" },
      { src: "14.jpg" },
      { src: "15.jpg" },
      { src: "16.jpg" },
    ],
    galleryButton: { colSpan: 4 },
    hasPlanOvalo: true,
    fichaTecnica:
      "https://www.ford.com.ar/content/dam/Ford/website-assets/latam/ar/nameplate/maverick/landing/pdf/far-maverick-ficha-tecnica.pdf",
    specs: {
      generales: [],
      motorizacion: [],
      exterior: [],
      interior: [],
      entretenimiento: [],
      seguridad: [],
    },
  },
];

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-car-catalog",
  templateUrl: "./car-catalog.component.html",
  styleUrls: ["./car-catalog.component.scss"]
})
export class CarCatalogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

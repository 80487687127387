import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CarService } from "src/app/services/car.services";

@Component({
  selector: "app-car-model",
  templateUrl: "./car-model.component.html",
  styleUrls: ["./car-model.component.scss"],
})
export class CarModelComponent implements OnInit, OnDestroy {
  isAutoplan = false;
  specificationTabs = [
    "generales",
    "motorizacion",
    "exterior",
    "interior",
    "entretenimiento",
    "seguridad",
  ];
  car: any = null;
  routerSubscription = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private carService: CarService
  ) {}

  ngOnInit() {
    this.getCarFromRouteParam();
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  get carWelcomeText() {
    console.log(this.car, this.car.welcomeText);
    return this.car.welcomeText
      ? `assets/imgs/modelos/${this.car.link}/${this.car.welcomeText}`
      : `assets/imgs/modelos/${this.car.link}/welcome-text.svg`;
  }

  getCarFromRouteParam() {
    this.routerSubscription = this.activeRoute.params.subscribe((params) => {
      if (params.hasOwnProperty("model")) {
        this.car = this.carService
          .getCars()
          .find((car) => car.link === params.model);
        console.log(this.car);
      }
    });

    this.activeRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.autoplan == "true") {
        this.isAutoplan = true;
      }
    });
  }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-car-portrait",
  templateUrl: "./car-portrait.component.html",
  styleUrls: ["./car-portrait.component.scss"]
})
export class CarPortraitComponent implements OnInit {
  @Input() car;
  @Input() withButtons;
  @Input() cotizarAhora;
  @Input() cotizarAhoraPlanOvalo;
  @Input() autoplan = false;
  @Input() limitedSize: boolean = false;

  constructor() {}

  ngOnInit() {}
}

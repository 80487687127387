import { Component, OnInit } from "@angular/core";
import { SlideWithTextComponent } from "src/app/components/slides/slide-with-text/slide-with-text.component";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-plan-ovalo",
  templateUrl: "./plan-ovalo.component.html",
  styleUrls: ["./plan-ovalo.component.scss"],
})
export class PlanOvaloComponent implements OnInit {
  PLAN_OVALO = CONTACTOS.planOvalo;
  slides = [
    {
      src: "assets/imgs/plan-ovalo/ranger.jpg",
      page: ["", "modelos", "ranger"],
      query: { modalidad: "plan-ovalo" },
      position: "center right",
      component: SlideWithTextComponent,
    },
  ];

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit, Input } from "@angular/core";
import { CONTACTOS } from "src/app/enums/contact.enum";

@Component({
  selector: "app-branches",
  templateUrl: "./branches.component.html",
  styleUrls: ["./branches.component.scss"],
})
export class BranchesComponent implements OnInit {
  @Input() withTitle = true;
  branches = [
    {
      name: "Quilmes",
      address: "Av. Hipolito Irigoyen 80",
      tel: "4002 0000",
      workingHours: "Lunes a Viernes 9 a 13hrs - 14 a 19hrs",
      posventaHours: "Lunes a Viernes 8 a 13hrs - 14 a 18hrs",
      admHours: "Lunes a Viernes 9 a 13hrs - 14 a 18hrs",
      wp: CONTACTOS.sucursales.quilmes.wp,
      locationLink: "https://goo.gl/maps/RAxbL9nhyY1gGiga6",
    },
    {
      name: "Sarandí",
      address: "Av. Mitre 2900",
      tel: "4116 6600",
      workingHours: "Lunes a Viernes 9 a 13hrs - 14 a 19hrs",
      posventaHours: "Lunes a Viernes 8 a 13hrs - 14 a 18hrs",
      admHours: "Lunes a Viernes 9 a 13hrs - 14 a 18hrs",
      wp: CONTACTOS.sucursales.sarandi.wp,
      locationLink: "https://goo.gl/maps/VpBXqgZ6SFc1qp3N8",
    },
    {
      name: "Varela",
      address: "Ruta Provincial 36 1161",
      tel: "3221 9118",
      workingHours: "Lunes a Viernes 9 a 13hrs - 14 a 19hrs",
      posventaHours: "Lunes a Viernes 8 a 13hrs - 14 a 18hrs",
      admHours: "Lunes a Viernes 9 a 13hrs - 14 a 18hrs",
      wp: CONTACTOS.sucursales.varela.wp,
      locationLink: "https://goo.gl/maps/MZ92DgHT4FNH825T9",
    },
  ];

  constructor() {}

  ngOnInit() {}

  wpClick(num) {
    window.location.href = `https://api.whatsapp.com/send?phone=${
      num || CONTACTOS.sucursalGenerico
    }&text=Hola!%20Quisiera%20hablar%20con%20un%20asesor!`;
  }
}

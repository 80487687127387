export const CONTACTOS = {
  sucursales: {
    quilmes: {
      posventa: "5491159078125",
      "0km": "5491159078105",
      planOvalo: "5491159078105",
      wp: "5491159078125",
    },
    sarandi: {
      posventa: "5491121840686",
      "0km": "5491162069503",
      planOvalo: "5491121870077",
      wp: "",
    },
    varela: {
      posventa: "5491159078207",
      "0km": "5491164040949",
      planOvalo: "5491168988512",
      wp: "5491159078207",
    },
  },
  "0km": "5491166491103",
  planOvalo: "5491159078195",
  posventa: "5491121840686",
  sucursalGenerico: "5491130559661",
  wpGenerico: "5491159078065",
  corporativo: "5491159078082",
};

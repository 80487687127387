import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  toggleNav = false;
  links = [
    { name: "0 KM", href: "0-km" },
    { name: "plan ovalo", href: "plan-ovalo" },
    {
      name: "posventa",
      items: [
        { name: "Posventa", href: "posventa" },
        {
          name: "Mantenimientos programados",
          href: "contacto",
          params: { tipo: "posventa" },
        },
        {
          name: "Accesorios y Repuestos",
          href: "contacto",
          params: { subject: "accesorrios" },
        },
        {
          name: "Ford Protect",
          external:
            "https://www.ford.com.ar/posventa/ford-protect/?gclid=CjwKCAjwh7H7BRBBEiwAPXjadj1-45SK-Cet8CT55gHENDW4Aap9NkSU9qBqaNzYGtEN2k0Gskxq4hoChEcQAvD_BwE",
        },
      ],
    },
    { name: "usados", href: "usados" },
    {
      name: "Ford Pro",
      href: "ford-pro",
    },
  ];

  constructor() {}

  ngOnInit() {}
}

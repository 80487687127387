import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-wp-button",
  templateUrl: "./wp-button.component.html",
  styleUrls: ["./wp-button.component.scss"],
})
export class WpButtonComponent implements OnInit {
  @Input() items: { number: string; name: string; message: string }[] = [{}] as any;
  @Input() type = "single";
  toggle = false;
  constructor() {}

  ngOnInit() {}

  open() {
    const { number, message } = (this.items && this.items[0]) || { number: '', message: ''};
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=${message}`);
  }

  contact(number, message) {
    window.open(
      `https://api.whatsapp.com/send?phone=${number}&text=${message}`
    );
  }

  toggleSubItem(evt, item) {
    evt.stopPropagation();
    item.open = !item.open;
  }
}

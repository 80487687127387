import { Component, OnInit, Input } from "@angular/core";
import { CarService } from "src/app/services/car.services";

@Component({
  selector: "app-car-list",
  templateUrl: "./car-list.component.html",
  styleUrls: ["./car-list.component.scss"]
})
export class CarListComponent implements OnInit {
  @Input() withButtons = false;
  cars;

  constructor(private carService: CarService) {
    this.cars = this.carService.getCars();
    console.log(this.carService.getCars());
    console.log(this.cars);
  }

  ngOnInit() {}
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-cta-button",
  templateUrl: "./cta-button.component.html",
  styleUrls: ["./cta-button.component.scss"]
})
export class CtaButtonComponent implements OnInit {
  @Input() type = "primary";
  @Input() text = "NO TEXT FOR THIS BUTTON";
  @Input() page = null;
  @Input() query = null;
  @Input() link = null;
  @Input() classes = "";
  @Input() disabled = false;

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { CarModelComponent } from "./pages/car-model/car-model.component";
import { CarCatalogComponent } from "./pages/car-catalog/car-catalog.component";
import { ContactoComponent } from "./pages/contacto/contacto.component";
import { PlanOvaloComponent } from "./pages/plan-ovalo/plan-ovalo.component";
import { NuevosComponent } from "./pages/nuevos/nuevos.component";
import { UsadosComponent } from "./pages/usados/usados.component";
import { CorporativosComponent } from "./pages/corporativos/corporativos.component";
import { OportunidadesPageComponent } from "./pages/oportunidades-page/oportunidades-page.component";
import { PosventaComponent } from "./pages/posventa/posventa.component";
import { NosotrosComponent } from "./pages/nosotros/nosotros.component";
import { ModelExistsGuard } from "./guards/model-exists/model-exists.guard";

const routes: Routes = [
  { path: "", redirectTo: "inicio", pathMatch: "full" },
  { path: "inicio", component: HomeComponent },
  {
    path: "modelos",
    children: [
      { path: "", component: CarCatalogComponent },
      {
        path: ":model",
        canActivate: [ModelExistsGuard],
        component: CarModelComponent,
      },
    ],
  },
  { path: "0-km", component: NuevosComponent },
  { path: "posventa", component: PosventaComponent },
  { path: "usados", component: UsadosComponent },
  { path: "ford-pro", component: CorporativosComponent },
  { path: "oportunidades", component: OportunidadesPageComponent },
  { path: "plan-ovalo", component: PlanOvaloComponent },
  { path: "contacto", component: ContactoComponent },
  { path: "nosotros", component: NosotrosComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
